import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Aside() {
  const location = useLocation(); // Get the current location to determine the active link
  const [dropdownVisible, setDropdownVisible] = useState(false);

  // This function checks if a given path is active
  const isActive = (path) => location.pathname === path;

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  return (
    <div className="aside shadow">
      <div className="main-logo">
        <img src="../assets/images/logo.png" alt="Logo" className="logo-img" />
      </div>
      <h5 className="menu-heading">Main Menu</h5>
      <Link to="/dashboard">
        <div className={`lists ${isActive("/dashboard") ? "active" : ""}`}>
          <i className="fa-solid fa-house"></i>&nbsp;Dashboard
        </div>
      </Link>
      <Link to="/order-detail">
        <div className={`lists ${isActive("/order-detail") ? "active" : ""}`}>
          <i className="fa-solid fa-bolt"></i>&nbsp;&nbsp;&nbsp;Order &nbsp;Detail
        </div>
      </Link>
      <Link to="/user-detail">
        <div className={`lists ${isActive("/user-detail") ? "active" : ""}`}>
          <i className="fa-solid fa-user"></i>&nbsp;&nbsp;&nbsp;User &nbsp;Detail
        </div>
      </Link>
      <div className={`lists ${dropdownVisible ? "active" : ""}`} onClick={toggleDropdown}>
        <i className="fa-solid fa-border-all"></i>&nbsp;&nbsp;&nbsp; Add
        <i className={`fa-solid ${dropdownVisible ? "fa-chevron-up" : "fa-chevron-down"} arrow-icon`}></i>
      </div>
      {dropdownVisible && (
        <div className="dropdown">
          <Link to="/add-category">
            <div className={`list ${isActive("/add-category") ? "active" : ""}`}>
              &nbsp;&nbsp; Add Category
            </div>
          </Link>
          <Link to="/add-product">
            <div className={`list ${isActive("/add-product") ? "active" : ""}`}>
              &nbsp;&nbsp;&nbsp;Add Product
            </div>
          </Link>
        </div>
      )}
    </div>
  );
}
