import React, { useEffect, useState } from "react";
import Aside from "./Aside";
import Dropdown from "./DropDown";
import axios from "axios";
import Api from "./Api";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function UserDetail() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [visibleColumns, setVisibleColumns] = useState({
    no: true,
    email: true,
    name: true,
    phone: true,
    loginDate: true,
    address: true,
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const FetchUsers = Api() + "/users";
      const response = await axios.get(FetchUsers, { withCredentials: true });
      setUsers(response.data);  
      setFilteredUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = users.filter(user =>
      user.email.toLowerCase().includes(value) ||
      user.full_name.toLowerCase().includes(value) ||
      user.phone_number.toLowerCase().includes(value) ||
      new Date(user.created_at).toLocaleDateString().toLowerCase().includes(value) ||
      user.address.toLowerCase().includes(value)
    );
    setFilteredUsers(filtered);
    setCurrentPage(1); // Reset to first page on search
  };

  // Get current users
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Export to PDF
  const exportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: ".user-table" });
    doc.save("users.pdf");
  };

  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  return (
    <>
      <div className="main-div">
        <Aside />
        <div className="content">
          <header className="header shadow">
            <div className="header-left">
              <i className="fa-solid fa-bars"></i>
              <h4 className="analystics fw-bold">User Detail</h4>
            </div>
            <Dropdown />
          </header>
          
          <main className="main-content">
            <div className="search-container ">
              <input
                type="text"
                placeholder="search here"
                className="search-input form-control"
                value={searchTerm}
                onChange={handleSearch}
              />
              <i className="fa fa-search search-icon"></i>
            </div>
            <div className="cards-4 shadow">
              <div className="download-lables">
                <div className="download-areas">
                  <div className="download-texts">Download into file</div>
                  <div className="button-containers">
                    <button className="action-buttons">
                      <CSVLink data={filteredUsers} filename={"users.csv"}>COPY</CSVLink>
                    </button>
                    <button className="action-buttons">
                      <CSVLink data={filteredUsers} filename={"users.csv"}>EXCEL</CSVLink>
                    </button>
                    <button className="action-buttons" onClick={exportPDF}>PDF</button>
                    <button className="action-buttons">COLUMN VISIBILITY</button>
                  </div>
                </div>
              </div>
  
              <table className="user-table">
                <thead>
                  <tr>
                    {visibleColumns.no && <th>NO</th>}
                    {visibleColumns.email && <th>EMAIL</th>}
                    {visibleColumns.name && <th>NAME</th>}
                    {visibleColumns.phone && <th>PHONE</th>}
                    {visibleColumns.loginDate && <th>LOGIN DATE</th>}
                    {visibleColumns.address && <th>ADDRESS</th>}
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.map((user, index) => (
                    <tr key={user.user_id}>
                      {visibleColumns.no && <td>{index + 1}</td>}
                      {visibleColumns.email && <td>{user.email}</td>}
                      {visibleColumns.name && <td>{user.full_name}</td>}
                      {visibleColumns.phone && <td>{user.phone_number}</td>}
                      {visibleColumns.loginDate && <td>{new Date(user.created_at).toLocaleDateString()}</td>}
                      {visibleColumns.address && <td>{user.address}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="pagination">
                <div className="pagination-info">
                  Showing {indexOfFirstUser + 1} to {Math.min(indexOfLastUser, filteredUsers.length)} of {filteredUsers.length} entries
                </div>
                <div className="pagination-controls">
                  <button className="pagination-previous" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                    <i className="fa fa-chevron-left"></i> Previous
                  </button>
                  <div className="page-buttons-container">
                    {Array.from({ length: Math.ceil(filteredUsers.length / usersPerPage) }, (_, index) => (
                      <button
                        key={index + 1}
                        className={`pagination-button ${currentPage === index + 1 ? "active" : ""}`}
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}
                  </div>
                  <button className="pagination-next" onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(filteredUsers.length / usersPerPage)}>
                    Next <i className="fa fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}