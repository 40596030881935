import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Api from "./Api";

export default function Dropdown() {
  const [admin, setAdmin] = useState({ name: "", email: "" });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdminProfile = async () => {
      try {
        const  FetchAdminProfile = Api() + "/admin/profile";
        const response = await axios.get(FetchAdminProfile, { withCredentials: true });
        setAdmin(response.data);
      } catch (error) {
        console.error("Error fetching admin profile:", error);
      }
    };

    fetchAdminProfile();
  }, []);
  const handleLogout = async () => {
    try {
        const LogoutApi = Api() + "/admin/logout";
        await axios.post(LogoutApi, null, { withCredentials: true });
        localStorage.removeItem("admin");  // Remove the admin key from localStorage
        setAdmin({ name: "", email: "" }); // Clear admin state in the component
        navigate("/"); // Redirect to sign-in page
    } catch (error) {
        console.error("Error logging out:", error);
    }
}

  return (
    <div className="header-right">
      <div className="profile">
        <div className="profile-icon">
          <i className="fa-solid fa-user fa-2xl"></i>
        </div>
        <div className="profile-info">
          <span className="profile-name">{admin.name}</span>
          <span className="profile-email">{admin.email}</span>
        </div>
        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          ></button>
          <ul className="dropdown-menu ">
            <li>
              <Link className="dropdown-item" to="#" onClick={handleLogout}>
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}