// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { withCookies } from 'react-cookie';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Dashboard from './components/Dashboard';
import OrderDetail from './components/OrderDetail';
import UserDetail from './components/UserDetail';
import AddCategory from './components/AddCategory';
import AddProduct from './components/AddProduct';
import ProtectedRoute from './ProtectedRoute';

function MyRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />

                {/* Protected admin routes */}
                <Route 
                    path="/dashboard" 
                    element={<ProtectedRoute><Dashboard /></ProtectedRoute>} 
                />
                <Route 
                    path="/order-detail" 
                    element={<ProtectedRoute><OrderDetail /></ProtectedRoute>} 
                />
                <Route 
                    path="/user-detail" 
                    element={<ProtectedRoute><UserDetail /></ProtectedRoute>} 
                />
                <Route 
                    path="/add-category" 
                    element={<ProtectedRoute><AddCategory /></ProtectedRoute>} 
                />
                <Route 
                    path="/add-product" 
                    element={<ProtectedRoute><AddProduct /></ProtectedRoute>} 
                />
            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
const MyRoutesWithCookies = withCookies(MyRoutes);
root.render(<MyRoutesWithCookies />);
