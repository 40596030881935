import Aside from "./Aside";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Dropdown from "./DropDown";
import Api from "./Api";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function AddCategory() {
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]); // New state for original categories
  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState(null);
  const [editingCategory, setEditingCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage] = useState(10);
  const [visibleColumns, setVisibleColumns] = useState({
    no: true,
    name: true,
    photo: true,
    action: true,
  });
  const [selectedFileName, setSelectedFileName] = useState(""); // State for selected file name
  const [selectedFileURL, setSelectedFileURL] = useState(""); // State for selected file URL

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const FetchCategoriesApi = Api() + "/categories";
    try {
      const response = await axios.get(FetchCategoriesApi);
      setCategories(response.data.categories); // Adjust based on the response structure
      setAllCategories(response.data.categories); // Set original categories
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", categoryName);
    if (categoryImage) {
      formData.append("image_url", categoryImage);
    }
    const AddCategoryApi = Api() + "/add-categories";
    try {
      await axios.post(AddCategoryApi, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setCategoryName("");
      setCategoryImage(null);
      setSelectedFileName(""); // Clear selected file name
      setSelectedFileURL(""); // Clear selected file URL
      fetchCategories(); // Refresh the category list
      closeModal(); // Close the modal
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  const handleEditCategory = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", categoryName);
    if (categoryImage) {
      formData.append("image_url", categoryImage);
    }
    const EditCategoryApi = Api() + `/categories/${editingCategory.id}`;
    try {
      await axios.put(EditCategoryApi, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setCategoryName("");
      setCategoryImage(null);
      setSelectedFileName(""); // Clear selected file name
      setSelectedFileURL(""); // Clear selected file URL
      setEditingCategory(null);
      fetchCategories(); // Refresh the category list
      closeModal2(); // Close the modal
    } catch (error) {
      console.error("Error editing category:", error);
    }
  };

  const handleDeleteCategory = async (id) => {
    const DeleteCategoryApi = Api() + `/categories/${id}`;
    if (window.confirm("Are you sure you want to delete this category?")) {
      try {
        await axios.delete(DeleteCategoryApi);
        fetchCategories(); // Refresh the category list
      } catch (error) {
        console.error("Error deleting category:", error);
      }
    }
  };

  const showModal = () => {
    setCategoryName("");
    setCategoryImage(null);
    setSelectedFileName(""); // Clear selected file name
    setSelectedFileURL(""); // Clear selected file URL
    const modal = document.getElementById("orderModal");
    modal.style.display = "block";
    modal.classList.add("show");
  };

  const closeModal = () => {
    const modal = document.getElementById("orderModal");
    modal.style.display = "none";
    modal.classList.remove("show");
  };

  const showModal2 = (category) => {
    setEditingCategory(category);
    setCategoryName(category.name);
    setCategoryImage(null); // Reset the image input
    setSelectedFileName(""); // Clear selected file name
    setSelectedFileURL(""); // Clear selected file URL
    const modal = document.getElementById("editOrderModal");
    modal.style.display = "block";
    modal.classList.add("show");
  };

  const closeModal2 = () => {
    setEditingCategory(null);
    setCategoryName("");
    setCategoryImage(null);
    setSelectedFileName(""); // Clear selected file name
    setSelectedFileURL(""); // Clear selected file URL
    const modal = document.getElementById("editOrderModal");
    modal.style.display = "none";
    modal.classList.remove("show");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCategoryImage(file);
    setSelectedFileName(file ? file.name : ""); // Update selected file name
    setSelectedFileURL(file ? URL.createObjectURL(file) : ""); // Update selected file URL
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = allCategories.filter(category =>
      category.name.toLowerCase().includes(value)
    );
    setCategories(filtered);
    setCurrentPage(1); // Reset to first page on search
  };

  // Get current categories
  const indexOfLastCategory = currentPage * categoriesPerPage;
  const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
  const currentCategories = categories.slice(indexOfFirstCategory, indexOfLastCategory);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Export to PDF
  const exportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: ".user-table" });
    doc.save("categories.pdf");
  };

  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  return (
    <>
      <div className="main-div">
        <Aside />
        <div className="content">
          <header className="header shadow">
            <div className="header-left">
              <i className="fa-solid fa-bars"></i>
              <h4 className="analystics fw-bold">Add Category</h4>
            </div>
            <Dropdown />
          </header>

          <main className="main-content">
            <div className="search-lead-container">
              <div className="search-container">
                <input
                  type="text"
                  placeholder="search here"
                  className="search-input form-control"
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <i className="fa fa-search search-icon"></i>
              </div>
            </div>

            <div className="cards-4 shadow">
              <div className="download-lable">
                <div className="download-area">
                  <div className="left-right-container">
                    <button className="new-category-button" onClick={showModal}>
                      <i className="fa-solid fa-plus"></i> New Category
                    </button>
                    <div className="download-content">
                      <div className="download-text">Download into file</div>
                      <div className="button-container">
                        <button className="action-buttons">
                          <CSVLink data={categories} filename={"categories.csv"}>COPY</CSVLink>
                        </button>
                        <button className="action-buttons">
                          <CSVLink data={categories} filename={"categories.csv"}>EXCEL</CSVLink>
                        </button>
                        <button className="action-buttons" onClick={exportPDF}>PDF</button>
                        <button className="action-buttons">COLUMN VISIBILITY</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <table className="user-table">
                <thead>
                  <tr>
                    {visibleColumns.no && <th>NO</th>}
                    {visibleColumns.name && <th>NAME</th>}
                    {visibleColumns.photo && <th>PHOTO</th>}
                    {visibleColumns.action && <th className="d-flex justify-content-center align-items-center">ACTION</th>}
                  </tr>
                </thead>
                <tbody>
                  {currentCategories.map((category, index) => (
                    <tr key={category.id}>
                      {visibleColumns.no && <td>{index + 1}</td>}
                      {visibleColumns.name && <td>{category.name}</td>}
                      {visibleColumns.photo && (
                        <td>
                          {category.image_url ? (
                            <img
                              src={`https://grocery-api.4born.in/images/${category.image_url}`}  // Ensure you're using 'image_url'
                              alt={category.name}
                              className="imgae-category img-fluid"
                            />
                          ) : (
                            "No Image"
                          )}
                        </td>
                      )}
                      {visibleColumns.action && (
                        <td>
                          <button className="edit-button" onClick={() => showModal2(category)}>Edit</button>
                          <button className="delete-button ms-2" onClick={() => handleDeleteCategory(category.id)}>Delete</button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="pagination">
                <div className="pagination-info">
                  Showing {indexOfFirstCategory + 1} to {Math.min(indexOfLastCategory, categories.length)} of {categories.length} entries
                </div>
                <div className="pagination-controls">
                  <button className="pagination-previous" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                    <i className="fa fa-chevron-left"></i> Previous
                  </button>
                  <div className="page-buttons-container">
                    {Array.from({ length: Math.ceil(categories.length / categoriesPerPage) }, (_, index) => (
                      <button
                        key={index + 1}
                        className={`pagination-button ${currentPage === index + 1 ? "active" : ""}`}
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}
                  </div>
                  <button className="pagination-next" onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(categories.length / categoriesPerPage)}>
                    Next <i className="fa fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <div className="modal" id="orderModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="order-card">
                <p className="modal-add-category">Add Category</p>
              </div>

              <div className="modal-context docs">
                <form onSubmit={handleAddCategory}>
                  <label>Name of Category</label>
                  <input
                    type="text"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    placeholder="Enter Category name"
                    className="input-field"
                    required
                  />

                  <div className="custom-file-input-wrapper">
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={handleFileChange}
                      required
                    />
                    <span className="custom-file-text">+ upload image</span>
                  </div>
                  {selectedFileName && <p className="file-name">{selectedFileName}</p>} {/* Display selected file name */}
               
                  <div className="modal-footer">
                    <button type="submit" className="update-button">Add Category</button>
                    <button type="button" className="close-button" onClick={closeModal}>
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="editOrderModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="order-card">
                <p className="modal-add-category">Edit Category</p>
              </div>

              <div className="modal-context docs">
                <form onSubmit={handleEditCategory}>
                  <label>Name of Category</label>
                  <input
                    type="text"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    placeholder="Edit category name"
                    className="input-field"
                    required
                  />

                  <div className="custom-file-input-wrapper">
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={handleFileChange}
                    />
                    <span className="custom-file-text">+ upload image</span>
                  </div>
                  {selectedFileName && <p className="file-name">File Name: {selectedFileName}</p>} {/* Display selected file name */}
                  {selectedFileURL && <p className="file-url">File URL: <a href={selectedFileURL} target="_blank" rel="noopener noreferrer">{selectedFileURL}</a></p>} {/* Display selected file URL */}
                  {editingCategory && editingCategory.image_url && !categoryImage && (
                    <div>
                      <img
                        src={`https://grocery-api.4born.in/images/${editingCategory.image_url}`}
                        alt={editingCategory.name}
                        className="imgae-category img-fluid"
                      />
                    </div>
                  )}

                  <div className="modal-footer">
                    <button type="submit" className="update-button">Update Category</button>
                    <button type="button" className="close-button" onClick={closeModal2}>
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}