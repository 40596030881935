import React, { useState, useEffect } from "react";
import axios from "axios";
import Aside from "./Aside";
import Dropdown from "./DropDown";
import Api from "./Api";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function AddProduct() {
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]); // New state for original products
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    category_id: "",
    name: "",
    price: "",
    about: "",
    photo: null,
  });
  const [editingProduct, setEditingProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const [selectedFileName, setSelectedFileName] = useState(""); // State for selected file name

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, []);

  const fetchProducts = async () => {
    const FetchProductsApi = Api() + "/products";
    try {
      const response = await axios.get(FetchProductsApi);
      setProducts(response.data.products);
      setAllProducts(response.data.products); // Set original products
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchCategories = async () => {
    const FetchCategoriesApi = Api() + "/categories";
    try {
      const response = await axios.get(FetchCategoriesApi);
      setCategories(response.data.categories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      photo: file,
    }));
    setSelectedFileName(file ? file.name : ""); // Update selected file name
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      data.append(key, value);
    });

    try {
      const AddProductsApi = Api() + "/add-products";
      const response = await axios.post(AddProductsApi, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      alert(response.data.message);
      fetchProducts(); // Refresh the product list
      setFormData({ category_id: "", name: "", price: "", about: "", photo: null }); // Clear form data
      setSelectedFileName(""); // Clear selected file name
      closeModal(); // Close the modal
    } catch (error) {
      console.error("Error adding product:", error);
      alert("Error adding product");
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      data.append(key, value);
    });

    try {
      const EditProductsApi = Api() + `/products/${editingProduct.id}`;
      const response = await axios.put(EditProductsApi, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      alert(response.data.message);
      fetchProducts(); // Refresh the product list
      closeModal2(); // Close the modal
    } catch (error) {
      console.error("Error editing product:", error);
      alert("Error editing product");
    }
  };

  const handleDelete = async (id) => {
    try {
      const DeleteProductsApi = Api() + `/products/${id}`;
      const response = await axios.delete(DeleteProductsApi);
      alert(response.data.message);
      fetchProducts(); // Refresh the product list
    } catch (error) {
      console.error("Error deleting product:", error);
      alert("Error deleting product");
    }
  };

  const showModal = () => {
    setFormData({ category_id: "", name: "", price: "", about: "", photo: null }); // Clear form data
    setSelectedFileName(""); // Clear selected file name
    const modal = document.getElementById("orderModal");
    modal.style.display = "block";
    modal.classList.add("show");
  };

  const showModal2 = (product) => {
    setEditingProduct(product);
    setFormData({
      category_id: product.category_id,
      name: product.name,
      price: product.price,
      about: product.about,
      photo: null,
    });
    setSelectedFileName(""); // Clear selected file name
    const modal = document.getElementById("editOrderModal");
    modal.style.display = "block";
    modal.classList.add("show");
  };

  const closeModal = () => {
    const modal = document.getElementById("orderModal");
    modal.style.display = "none";
    modal.classList.remove("show");
  };

  const closeModal2 = () => {
    setEditingProduct(null);
    setFormData({
      category_id: "",
      name: "",
      price: "",
      about: "",
      photo: null,
    });
    setSelectedFileName(""); // Clear selected file name
    const modal = document.getElementById("editOrderModal");
    modal.style.display = "none";
    modal.classList.remove("show");
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = allProducts.filter(product =>
      product.name.toLowerCase().includes(value) ||
      product.price.toString().includes(value) ||
      product.about.toLowerCase().includes(value) ||
      product.category_name.toLowerCase().includes(value)
    );
    setProducts(filtered);
    setCurrentPage(1); // Reset to first page on search
  };

  // Get current products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Export to PDF
  const exportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: ".user-table" });
    doc.save("products.pdf");
  };

  return (
    <>
      <div className="main-div">
        <Aside />
        <div className="content">
          <header className="header shadow">
            <div className="header-left">
              <i className="fa-solid fa-bars"></i>
              <h4 className="analystics fw-bold">Add Product</h4>
            </div>
            <Dropdown />
          </header>

          <main className="main-content">
            <div className="search-lead-container">
              <div className="search-container">
                <input
                  type="text"
                  placeholder="search here"
                  className="search-input form-control"
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <i className="fa fa-search search-icon"></i>
              </div>
            </div>

            <div className="cards-4 shadow">
              <div className="download-lable">
                <div className="download-area">
                  <div className="left-right-container">
                    <button className="new-category-button" onClick={showModal}>
                      <i className="fa-solid fa-plus"></i> New Product
                    </button>
                    <div className="download-content">
                      <div className="download-text">Download into file</div>
                      <div className="button-container">
                        <button className="action-buttons">
                          <CSVLink data={products} filename={"products.csv"}>COPY</CSVLink>
                        </button>
                        <button className="action-buttons">
                          <CSVLink data={products} filename={"products.csv"}>EXCEL</CSVLink>
                        </button>
                        <button className="action-buttons" onClick={exportPDF}>PDF</button>
                        <button className="action-buttons">COLUMN VISIBILITY</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <table className="user-table">
                <thead>
                  <tr>
                    <th>NO</th>
                    <th>CATEGORY</th>
                    <th>PHOTO</th>
                    <th>NAME</th>
                    <th>PRICE</th>
                    <th>ABOUT</th>
                    <th className="d-flex justify-content-center align-items-center">
                      ACTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentProducts.map((product, index) => (
                    <tr key={product.id}>
                      <td>{index + 1}</td>
                      <td>{product.category_name}</td>
                      <td>
                        {product.photo ? (
                          <img src={`https://grocery-api.4born.in/images/${product.photo}`} alt="product" width="50" height="50" />
                        ) : (
                          "No Image"
                        )}
                      </td>
                      <td>{product.name}</td>
                      <td>{product.price}</td>
                      <td>
                        {product.about.length > 50 ? (
                          <span className="tooltip">
                            {product.about.substring(0, 50)}...
                            <span className="tooltiptext">{product.about}</span>
                          </span>
                        ) : (
                          product.about
                        )}
                      </td>
                      <td>
                        <button className="edit-button" onClick={() => showModal2(product)}>Edit</button>
                        <button className="delete-button ms-2" onClick={() => handleDelete(product.id)}>Delete</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="pagination">
                <div className="pagination-info">
                  Showing {indexOfFirstProduct + 1} to {Math.min(indexOfLastProduct, products.length)} of {products.length} entries
                </div>
                <div className="pagination-controls">
                  <button className="pagination-previous" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                    <i className="fa fa-chevron-left"></i> Previous
                  </button>
                  <div className="page-buttons-container">
                    {Array.from({ length: Math.ceil(products.length / productsPerPage) }, (_, index) => (
                      <button
                        key={index + 1}
                        className={`pagination-button ${currentPage === index + 1 ? "active" : ""}`}
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}
                  </div>
                  <button className="pagination-next" onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(products.length / productsPerPage)}>
                    Next <i className="fa fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <div className="modal" id="orderModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="order-card">
                <p className="modal-add-category">Add Product</p>
              </div>

              <div className="modal-context docs">
                <form onSubmit={handleSubmit}>
                  <label>Select Category</label>
                  <select
                    name="category_id"
                    className="input-field"
                    value={formData.category_id} // Ensure value is set to formData
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>

                  <div className="custom-file-input-wrapper">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="inputGroupFile03"
                      aria-describedby="inputGroupFileAddon03"
                      aria-label="Upload"
                      onChange={handleFileChange}
                      required
                    />
                    <span className="custom-file-text">+ upload image</span>
                  </div>
                  {selectedFileName && <p className="file-name">{selectedFileName}</p>} {/* Display selected file name */}
                  <label>Product Name</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Product Name"
                    className="input-field"
                    value={formData.name} // Ensure value is set to formData
                    onChange={handleChange}
                    required
                  />
                  <label>Product Price</label>
                  <input
                    type="text"
                    name="price"
                    placeholder="Product Price"
                    className="input-field"
                    value={formData.price} // Ensure value is set to formData
                    onChange={handleChange}
                    required
                  />
                  <label>About Product</label>
                  <textarea
                    name="about"
                    rows={4}
                    cols={35}
                    value={formData.about} // Ensure value is set to formData
                    onChange={handleChange}
                    required
                  ></textarea>

                  <div className="modal-footer">
                    <button type="submit" className="update-button">Add Product</button>
                    <button type="button" className="close-button" onClick={closeModal}>
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="editOrderModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="order-card">
                <p className="modal-add-category">Edit Product</p>
              </div>

              <div className="modal-context docs">
                <form onSubmit={handleEditSubmit}>
                  <label>Select Category</label>
                  <select
                    name="category_id"
                    className="input-field"
                    value={formData.category_id}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>

                  <div className="custom-file-input-wrapper">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="inputGroupFile03"
                      aria-describedby="inputGroupFileAddon03"
                      aria-label="Upload"
                      onChange={handleFileChange}
                    />
                    <span className="custom-file-text">+ upload image</span>
                  </div>
                  {selectedFileName && <p className="file-name">{selectedFileName}</p>} {/* Display selected file name */}
                  {editingProduct && editingProduct.photo && !formData.photo && (
                    <div>
                      <img
                        src={`https://grocery-api.4born.in/images/${editingProduct.photo}`}
                        alt={editingProduct.name}
                        className="imgae-category img-fluid"
                      />
                    </div>
                  )}
                  <label>Product Name</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Product Name"
                    className="input-field"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <label>Product Price</label>
                  <input
                    type="text"
                    name="price"
                    placeholder="Product Price"
                    className="input-field"
                    value={formData.price}
                    onChange={handleChange}
                    required
                  />
                  <label>About Product</label>
                  <textarea
                    name="about"
                    rows={4}
                    cols={35}
                    value={formData.about}
                    onChange={handleChange}
                    required
                  ></textarea>

                  <div className="modal-footer">
                    <button type="submit" className="update-button">Update Product</button>
                    <button type="button" className="close-button" onClick={closeModal2}>
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}