import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import Api from './Api';

export default function SignUp() {
    const [formData, setFormData] = useState({
        mobile_number: "",
        password: "",
        name: "",
        email: "",
        gender: "",
        alternative_mobile: ""
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
// SignIn.js (only showing relevant code here)
const handleSubmit = async (e) => {
    e.preventDefault();
    const SignInApi = Api() + '/signup';
    try {
        const response = await axios.post(SignInApi, formData, { withCredentials: true });
        alert(response.data.message);
        
        // Set authentication flag in localStorage
        localStorage.setItem('isAuthenticated', 'true');
        
        navigate('/');
    } catch (error) {
        alert(`something went wrong! please try again`);
    }
};


    return (
        <>
            <div className="container signup-container">
                <div className="card cards-2 shadow">
                    <h1 className="heading">Sign up</h1>
                    <p className="description">Amet minim mollit non desert.</p>
                    <form onSubmit={handleSubmit}>  {/* onSubmit handles form submission */}
                        <div className="spaces-2">
                            <div className="input-wrapper">
                                <label className='form-label e-mails'>Mobile number</label>
                                <input 
                                    type="text" 
                                    name="mobile_number"
                                    value={formData.mobile_number}
                                    onChange={handleChange} 
                                    className='passs-word form-control'
                                    placeholder="Enter &nbsp;your &nbsp;number"
                                    required 
                                />
                            </div>
                            <div className="input-wrapper password-label">
                                <label className='form-label pass-word-2'>Create Password</label>
                                <input 
                                    type="password" 
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange} 
                                    className='passs-word form-control'
                                    placeholder="Enter &nbsp;your &nbsp;new &nbsp;Password"
                                    required 
                                />
                            </div>
                            <div className="input-wrapper">
                                <label className='form-label pass-word-2'>Full Name</label>
                                <input 
                                    type="text" 
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange} 
                                    className='passs-word form-control'
                                    placeholder="Enter &nbsp;your &nbsp;full &nbsp;Name"
                                    required 
                                />
                            </div>
                            <div className="input-wrapper">
                                <label className='form-label pass-word-2'>Email</label>
                                <input 
                                    type="email" 
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange} 
                                    className='passs-word form-control'
                                    placeholder="Enter &nbsp;your &nbsp;email"
                                    required 
                                />
                            </div>
                            <div className="d-flex gender">Select gender
                                <div className="input-left d-flex">
                                    <div className="inputs">
                                        <input 
                                            type="radio" 
                                            name="gender" 
                                            value="female"
                                            onChange={handleChange} 
                                            className="form-input-check" 
                                        />
                                        <label className="female-btn" htmlFor="female">Female</label>
                                    </div>
                                    <div className="inputs">
                                        <input 
                                            type="radio" 
                                            name="gender" 
                                            value="male"
                                            onChange={handleChange} 
                                            className="form-input-check" 
                                        />
                                        <label className="male-btn" htmlFor="male">Male</label>
                                    </div>
                                </div>
                            </div>
                            <div className="input-wrapper">
                                <label className='form-label pass-word-2'>Alternative number</label>
                                <input 
                                    type="text" 
                                    name="alternative_mobile"
                                    value={formData.alternative_mobile}
                                    onChange={handleChange} 
                                    className='passs-word form-control'
                                    placeholder="Enter &nbsp;your &nbsp;alternative &nbsp;mobile &nbsp;number"
                                    required 
                                />
                            </div>
                        </div>
                        <button type="submit" className="submit">Continue</button> {/* Removed onClick */}
                        <p className="signup-link">Have an account? <Link to="/">Sign in</Link></p>
                    </form>
                </div>
            </div>
        </>
    );
}