import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Api from './Api';

export default function SignIn() {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();  // For navigation after sign-in

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();  // Prevent page reload
        const SignInApi = Api() + '/signin';  // API URL
        try {
            const response = await axios.post(SignInApi, formData, { withCredentials: true });  // Call API
            alert(response.data.message);  // Show success message
            localStorage.setItem('admin', JSON.stringify(response.data.user)); // Store user info
            setFormData({ email: '', password: '' });  // Clear form data
            navigate('/dashboard');  // Navigate to home or dashboard after sign-in
        } catch (error) {
            alert(`Invalid email and password`);  // Handle errors
            setFormData({ email: '', password: '' });  // Clear form data
        }
    };

    return (
        <div className="container">
            <div className="card cards shadow">
                <h1 className="heading">Sign in</h1>
                <p className="description">Amet minim mollit non desert.</p>
                <form onSubmit={handleSubmit}> 
                    <div className="spaces">
                        <div className="input-wrapper">
                            <label className='form-label e-mail'>E-mail</label>
                            <input 
                                type="email" 
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className='passs-word form-control' 
                                placeholder='example@gmail.com'
                                required 
                            />
                        </div>
                        <div className="input-wrapper password-label margins">
                            <label className='form-label pass-word'>Enter Password</label>
                            <input 
                                type={showPassword ? "text" : "password"} 
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                className='passs-word form-control' 
                                placeholder='********'
                                required 
                            />
                            <span className="password-toggle" onClick={togglePasswordVisibility}>
                                <i className={showPassword ? "fa-regular fa-eye-slash" : "fa-regular fa-eye"}></i>
                            </span>
                        </div>
                    </div>
                    <button type="submit" className="submit">Sign In</button>
                    <p className="signup-link">
                        Don't have an account? <a href="/signup">Sign up</a>
                    </p>
                </form>
            </div>
        </div>
    );
}