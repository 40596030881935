import React, { useState, useEffect } from "react";
import axios from "axios";
import Aside from "./Aside";
import Dropdown from "./DropDown";
import Api from "./Api";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function OrderDetail() {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const FetchOrders = Api() + "/orders";
      const response = await axios.get(FetchOrders);
      const sortedOrders = response.data.orders.sort((a, b) => new Date(b.order_date) - new Date(a.order_date));
      setOrders(sortedOrders);
      setFilteredOrders(sortedOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const fetchOrderDetails = async (orderId) => {
    try {
      const FetchOrderDetails = Api() + `/order-details/${orderId}`;
      const response = await axios.get(FetchOrderDetails);
      setOrderDetails(response.data.order);
      setCartItems(response.data.cartItems);
      showModal();
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const updateOrderStatus = async (orderId, status) => {
    try {
      const UpdateOrderStatusApi = Api() + `/orders/${orderId}/status`;
      await axios.put(UpdateOrderStatusApi, { status });

      // Fetch the updated status from the backend
      const FetchOrderStatusApi = Api() + `/orders/${orderId}/status`;
      const response = await axios.get(FetchOrderStatusApi);

      // Update the order status in the state
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId ? { ...order, order_status: response.data.order_status } : order
        )
      );
      setFilteredOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId ? { ...order, order_status: response.data.order_status } : order
        )
      );

      if (status === 'accepted') {
        alert('Order accepted');
      } else if (status === 'decline') {
        alert('Order declined');
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const showModal = () => {
    const modal = document.getElementById("orderModal");
    modal.style.display = "block";
    modal.classList.add("show");
  };

  const closeModal = () => {
    const modal = document.getElementById("orderModal");
    modal.style.display = "none";
    modal.classList.remove("show");
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = orders.filter(order =>
      order.id.toString().includes(value) ||
      order.user_name.toLowerCase().includes(value) ||
      order.delivery_address.toLowerCase().includes(value) ||
      order.total.toString().includes(value) ||
      new Date(order.order_date).toLocaleDateString().toLowerCase().includes(value)
    );
    setFilteredOrders(filtered);
    setCurrentPage(1); // Reset to first page on search
  };

  // Get current orders
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Export to PDF
  const exportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: ".user-table" });
    doc.save("orders.pdf");
  };

  return (
    <>
      <div className="main-div">
        <Aside />
        <div className="content">
          <header className="header shadow">
            <div className="header-left">
              <i className="fa-solid fa-bars"></i>
              <h4 className="analystics fw-bold">Order Detail</h4>
            </div>
            <Dropdown />
          </header>

          <main className="main-content">
            <div className="search-lead-container">
              <div className="search-container">
                <input
                  type="text"
                  placeholder="search here"
                  className="search-input form-control"
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <i className="fa fa-search search-icon"></i>
              </div>
            </div>

            <div className="cards-4 shadow">
              <div className="download-lables">
                <div className="download-areas">
                  <div className="download-texts">Download into file</div>
                  <div className="button-containers">
                    <button className="action-buttons">
                      <CSVLink data={filteredOrders} filename={"orders.csv"}>COPY</CSVLink>
                    </button>
                    <button className="action-buttons">
                      <CSVLink data={filteredOrders} filename={"orders.csv"}>EXCEL</CSVLink>
                    </button>
                    <button className="action-buttons" onClick={exportPDF}>PDF</button>
                    <button className="action-buttons">COLUMN VISIBILITY</button>
                  </div>
                </div>
              </div>
              <table className="user-table">
                <thead>
                  <tr>
                    <th>NO</th>
                    <th>NAME</th>
                    <th>CREATED AT</th>
                    <th>AMOUNT</th>
                    <th>DELIVERY ADDRESS</th>
                    <th>VIEW ORDER DETAIL</th>
                    <th className="d-flex justify-content-center align-items-center">
                      ACTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order, index) => (
                    <tr key={order.id}>
                      <td>{indexOfFirstOrder + index + 1}</td> {/* Manual ID */}
                      <td>{order.user_name}</td>
                      <td>{new Date(order.order_date).toLocaleDateString()}</td>
                      <td>{order.total}</td>
                      <td className="delivery-address-cell">{order.delivery_address}</td>
                      <td className="d-flex justify-content-center align-items-center">
                        <button className="view-button" onClick={() => fetchOrderDetails(order.id)}>
                          <i className="fa-regular fa-eye"></i>
                        </button>
                      </td>
                      <td>
                        {order.order_status === 'accepted' ? (
                          <span>Accepted</span>
                        ) : order.order_status === 'decline' ? (
                          <span>Declined</span>
                        ) : (
                          <>
                            <button className="accept-button" onClick={() => updateOrderStatus(order.id, 'accepted')}>Accept</button>
                            <button className="decline-button" onClick={() => updateOrderStatus(order.id, 'decline')}>Decline</button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="pagination">
                <div className="pagination-info">
                  Showing {indexOfFirstOrder + 1} to {Math.min(indexOfLastOrder, filteredOrders.length)} of {filteredOrders.length} entries
                </div>
                <div className="pagination-controls">
                  <button className="pagination-previous" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                    <i className="fa fa-chevron-left"></i> Previous
                  </button>
                  <div className="page-buttons-container">
                    {Array.from({ length: Math.ceil(filteredOrders.length / ordersPerPage) }, (_, index) => (
                      <button
                        key={index + 1}
                        className={`pagination-button ${currentPage === index + 1 ? "active" : ""}`}
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}
                  </div>
                  <button className="pagination-next" onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(filteredOrders.length / ordersPerPage)}>
                    Next <i className="fa fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <div className="modal" id="orderModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="order-card">
                <p className="view-order">View Order</p>
                <table className="modal-table">
                  <tbody>
                    {cartItems.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <img
                            src={`https://grocery-api.4born.in/images/${item.photo}`}
                            alt={item.name}
                            className="food-img"
                          />
                        </td>
                        <td>
                          {item.name}
                          <br />
                          ₹{item.price}
                        </td>
                        <td>{item.quantity}</td>
                        <td>₹{item.total_price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="card cards-5">
                  <div className="summary-item">
                    <span className="Subtotal">Subtotal</span> <span>₹{orderDetails?.subtotal}</span>
                  </div>
                  <div className="summary-item">
                    <span className="Subtotal">Delivery</span> <span>₹{orderDetails?.delivery_charge}</span>
                  </div>
                  <div className="summary-item total">
                    <span className="Subtotal">Total</span> <span>₹{orderDetails?.total}</span>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <span className="close-button" onClick={closeModal}>
                  Close
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}