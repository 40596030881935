import React, { useState, useEffect } from "react";
import axios from "axios";
import Aside from "./Aside";
import Dropdown from "./DropDown";
import Api from "./Api";

export default function Dashboard() {
  const [dashboardData, setDashboardData] = useState({
    totalOrders: 0,
    totalUsers: 0,
    totalOrderAmount: 0,
    totalCategories: 0,
    totalProducts: 0,
  });

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const DashboardDataApi = Api() + "/dashboard-data";
      const response = await axios.get(DashboardDataApi);
      setDashboardData(response.data);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  return (
    <>
      <div className="main-div">
        <Aside />
        <div className="content">
          <header className="header shadow">
            <div className="header-left">
              <i className="fa-solid fa-bars"></i>
              <h4 className="analystics fw-bold">Analytics</h4>
            </div>
            <Dropdown />
          </header>

          <main className="main-content">
            <div className="main-content-header">
              client
            </div>
            <div className="card-container">
              <div className="card cards-3 shadow">
                <div className="headers-3">
                  Total Order
                </div>
                <div className="order-amount fw-bold">
                  {dashboardData.totalOrders}
                </div>
              </div>
              <div className="card cards-3 shadow">
                <div className="headers-3">
                  Total User
                </div>
                <div className="order-amount fw-bold">
                  {dashboardData.totalUsers}
                </div>
              </div>
              <div className="card cards-3 shadow">
                <div className="headers-3">
                  Order Amount
                </div>
                <div className="order-amount fw-bold">
                  ₹{dashboardData.totalOrderAmount}
                </div>
              </div>
            </div>
            <div className="main-content-header-2">
              Manage
            </div>
            <div className="card-container">
              <div className="card cards-3 shadow">
                <div className="headers-3">
                  Added Category 
                </div>
                <div className="order-amount fw-bold">
                  {dashboardData.totalCategories}
                </div>
              </div>
              <div className="card cards-3 shadow">
                <div className="headers-3">
                  Added Products 
                </div>
                <div className="order-amount fw-bold">
                  {dashboardData.totalProducts}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}